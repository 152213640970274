import { Button, Col, Grid, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate, useLocation, Link } from 'react-router-dom'
import indivumedLogo from '../assets/indivumed_logo_rgb.svg'
import useAppStore from '../state/App'
import AppSideBar from './AppSideBar'
import './NavBar.scss'

const NavBar = (): React.ReactElement => {
  const { t } = useTranslation()
  const navbarHeader: string = useAppStore(state => state.navbarHeader)
  const CLINICS_URL = '/clinics'
  const CHANGE_LANGUAGE_URL = '/changeLanguage'
  const CHANGE_PASSWORD_URL = '/changePassword'
  const location = useLocation()
  const navigate = useNavigate()
  const editorView = useMatch('/clinics/:clinicId/:editorType/:formDocumentId/:formMetaDataId')
  const caseSummaryView = useMatch('/clinics/:clinicId/caseSummary/:formDocumentId/')
  const followUpSummaryView = useMatch('/clinics/:clinicId/followUpSummary/:formDocumentId/')
  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()

  const onBackButtonClick = (): void => {
    if (editorView !== null) {
      const { clinicId } = editorView.params
      navigate(`/clinics/${clinicId}`)
    } else if (
      location.pathname === (CHANGE_LANGUAGE_URL || CHANGE_PASSWORD_URL) ||
      caseSummaryView ||
      followUpSummaryView
    ) {
      navigate(-1)
    } else {
      navigate('/clinics')
    }
  }

  const renderNavbarHeader = (): React.ReactElement => (
    <>
      {location.pathname !== CLINICS_URL && (
        <Button shape="round" className="me-3" onClick={onBackButtonClick}>
          {t('backButton')}
        </Button>
      )}
      <span className={editorView !== null ? 'fw-bold' : undefined}>{navbarHeader}</span>
    </>
  )

  return (
    <Row className="app-navbar d-flex align-content-center">
      <Col
        span={breakpoint.xxl ? 8 : 12}
        className="d-flex h-100 justify-content-start navbar-items-padding align-items-center"
      >
        <span className="indivumed-header-text"> {renderNavbarHeader()}</span>
      </Col>
      {breakpoint.xxl && (
        <Col span={8} className="justify-content-center h-100 d-flex align-items-center">
          <Link to="/clinics">
            <img src={indivumedLogo} alt="IndivumedLogo" className="navbar-indivumed-logo" />
          </Link>
        </Col>
      )}
      <Col
        span={breakpoint.xxl ? 8 : 12}
        className="d-flex justify-content-end align-items-center h-100 navbar-items-padding"
      >
        <AppSideBar />
      </Col>
    </Row>
  )
}

export default NavBar
